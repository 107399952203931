import React from "react";
import { HeaderLogoContainer, MainHeader } from "../styles/headerStyles";
import { LightspeedLogoURL } from "../assets/constants";

const Header = () => {
  return (
    <MainHeader>
      <HeaderLogoContainer>
        {/*<img src={LightspeedLogoURL} alt="Lightspeed logo"></img>*/}
        <h1>scj643&apos;s Lightspeed livestream</h1>
      </HeaderLogoContainer>
    </MainHeader>
  );
};

export default Header;
